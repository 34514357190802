import { sesClient } from "./sesClient";
import { SendEmailCommand }  from "@aws-sdk/client-ses";
var AWS = require('aws-sdk');

const sendEmailToAdmin = (apiUser) =>{
    console.log('This is our api user info' + JSON.stringify(apiUser))

    AWS.config.update({accessKeyId: "", secretAccessKey: "",region: "us-east-1"});

    const params = {
        Destination: {
          /* required */
          CcAddresses: [
            /* more items */
          ],
          ToAddresses: [
            "ddelahoussaye@alliancesafetycouncil.org", //RECEIVER_ADDRESS
            /* more To-email addresses */
          ],
        },
        Message: {
            /* required */
            Body: {
              /* required */
              Html: {
                Charset: "UTF-8",
                Data: "A NEW USER HAS REQUESTED ACCESS TO OUR DEV PORTAL ! Here is some more information about the user : \n"
                + "Name : " + apiUser.firstname + "\n" +
                + "Email : " + apiUser.email + "\n"
                + "Phone Number : " + apiUser.phone + "\n"
                + "Company : " + apiUser.company + "\n" 
                + "Role : " + apiUser.role + "\n"
                + "Country" + apiUser.country + "\n"
                + "API " + apiUser.api,  
              },
              Text: {
                Charset: "UTF-8",
                Data: "TEXT_FORMAT_BODY",
              },
            },
            Subject: {
              Charset: "UTF-8",
              Data: "EMAIL_SUBJECT",
            },
          },
        Source: "ddelahoussaye@alliancesafetycouncil.org", // SENDER_ADDRESS
        ReplyToAddresses: [
          /* more items */
        ],
      };
    
      var sendPromise = new AWS.SES({apiVersion: '2010-12-01'}).sendEmail(params).promise();
      console.log('Sending email')

// Handle promise's fulfilled/rejected states
sendPromise.then(
  function(data) {
    console.log(data.MessageId);
  }).catch(
    function(err) {
    console.error(err, err.stack);
  });
}

export default sendEmailToAdmin