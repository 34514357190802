import React, { useState } from "react";
import { useForm } from 'react-hook-form';
import { NavLink } from "react-router-dom";
import "./Navigation.css";
import logo from '../../assets/logos/LogoASC.png'
import Modal from 'react-modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import sendEmailToAdmin from "../../utils/SendMail";
import MenuItem from '@mui/material/MenuItem';
import { Checkmark } from 'react-checkmark'
import { Link } from "react-router-dom";
import { Config } from "../../AppVars.js"

const customStyles = {
  content: {
    top: '10%'
  },
};

const customStylesThankYou = {
  content: {
    top: '35%',
    left: '50%',
    right: 'auto',
    bottom: '20%',
    marginRight: '-50%',
    width: '60%',
    transform: 'translate(-40%, -10%)',
  },
};

function NavBar() {
  const [click, setClick] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);

  const apis = [{
    value: 'Courses API',
    label: 'Courses',
  }, {
    value: 'Curriculums API',
    label: 'Curriculums',
  }, {
    value: 'DISA API',
    label: 'DISA',
  }];

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handleClick = () => setClick(!click);

  const modalOnClick = () => {
    openModal()
  };

  const [api, setApi] = React.useState('EUR');

  const handleChange = (event) => {
    setApi(event.target.value);
  };

  const { register, handleSubmit } = useForm();

  const [thankYou, setThankYou] = useState(false)
  const closeThankYou = () =>{
    setThankYou(false)
  }

  const onSubmit = (data) => {
    closeModal()
    setThankYou(true)

    // Post Data
    // const postData = {
    //   firstname: data.name,
    //   email: data.email,
    //   phone: data.phone,
    //   company: data.company,
    //   role: data.role,
    //   country: data.country,
    //   api: api
    // };

    //sendEmailToAdmin(postData)
  }

  return (
    <div>
      <nav className="navbar">
        <div className="nav-container">
          <NavLink exact to="/" className="nav-logo">
           <img src={logo} alt="Alliance Safety Council Logo" />
            <i className="fas fa-code"></i>
          </NavLink>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                exact
                to="/"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Home
              </NavLink>
            </li>
            {/*<li className="nav-item">
              <NavLink
                exact
                to="/about"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                About
              </NavLink>
            </li>*/}
            {/*<li className="nav-item">
              <NavLink
                exact
                to="/blog"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Blog
              </NavLink>
            </li>*/}
            <li className="nav-item">
              <NavLink
                exact
                to="/ContactUs"
                activeClassName="active"
                className="nav-links"
                onClick={modalOnClick}
              >
                Contact Us
              </NavLink>
            </li>
            {Config.privacyPolicy.enabled &&
              <li className="nav-item">
                <Link
                  exact
                  to={{ pathname: Config.privacyPolicy.linkUrl }} target="_blank"
                  activeClassName="active"
                  className="nav-links"
                >
                  Privacy Policy
                </Link>
               </li>
            }
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
          </div>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Contact Modal"
            style={customStyles}
          >
            <h1 style={{textAlign: "center", fontWeight: 900, fontSize: '30px'}}>Tell us a little about yourself !</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack
                component="form"
                spacing={2}
                noValidate
                autoComplete="off"
              >
                <Box
                  sx={{
                    width: '80%',
                    maxWidth: '100%',
                    marginLeft: '150px',
                    '& .MuiTextField-root': { width: '100%' },
                  }}
                >
                  <TextField
                    fullWidth
                    label="Full Name"
                    id="name"
                    {...register('name', { required: true })}
                    required
                    margin="normal"
                    helperText="Please enter your full name"
                  />
                  <TextField
                    fullWidth
                    label="Email"
                    id="email"
                    {...register('email', { required: true })}
                    required
                    margin="normal"
                    helperText="Please enter your email"
                  />
                  <TextField
                    fullWidth
                    label="Phone"
                    id="phone"
                    {...register('phone', { required: true })}
                    required
                    margin="normal"
                    helperText="Please enter your phone number"/>
                  <TextField
                    fullWidth
                    label="Company"
                    id ="company"
                    {...register('company', { required: true })}
                    margin="normal"
                    helperText="Please enter your company name"
                  />
                  <TextField
                    fullWidth
                    label="Role"
                    id="role"
                    {...register('role', { required: true })}
                    margin="normal"
                    helperText="Please enter your current role or title"
                  />
                  <TextField
                    fullWidth
                    label="Country"
                    id="country"
                    {...register('country', { required: true })}
                    required
                    margin="normal"
                    helperText="Please enter your Country"
                  />
                  <TextField
                    id="apiSelection"
                    select
                    label="Select"
                    value={api}
                    onChange={handleChange}
                    helperText="Please select your API"
                  >
                    {apis.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Stack>
              <input type="submit" style={{
                backgroundColor: "#084d74",
                color: "white",
                fontSize: "20px",
                padding: "10px",
                borderRadius: "5px",
                margin: "10px",
                cursor: "pointer",
                marginLeft:"575px",
                width: "30%"
              }}></input>
            </form>
          </Modal>
        </div>
        <Modal
          isOpen={thankYou}
          style={customStylesThankYou}
          onRequestClose={closeThankYou}
        >
          <h1 style={{textAlign: "center", fontWeight: 900, fontSize: '30px'}}>Thank you for Subscribing to our Developer Portal !</h1>
          <h1 style={{textAlign: "center", fontSize: '30px'}}>We will be in contact with you shortly so you can access the future through our API Gateway !</h1>
          <Checkmark size='256px' color='#32cd32'/>
        </Modal>
      </nav>
    </div>
  );
}

export default NavBar;