import { StoplightProject } from '@stoplight/elements-dev-portal'
import '@stoplight/elements/styles.min.css'
import './App.css'
import NavBar from './components/Navigation/Navigation'
import { BrowserRouter as Router } from "react-router-dom"
import { Config } from "./AppVars.js"

function App() {
  return (
    <div className="App">
      <div>
        <Router>
          <NavBar />
        </Router>
      </div>
      <div style={{ paddingTop: '50px', width: '100%', maxHeight: '100%', minHeight: "100%" }}>
        <StoplightProject projectId={Config.stoplight.projectId} hideMocking={true} />
      </div>
    </div>
  );
}

export default App